import { NotificationContext } from '@login/context/UseNotificationContext';
import { getErrorMessage } from '@login/utils/getErrorMessage';
import { useContext } from 'react';
import { MutatorOptions } from 'swr';
import useSWRMutation from 'swr/mutation';

export type UseMutationParams<T> = {
  args: T;
  options?: {
    displayToast?: boolean;
    isSuccessGlobalTrigger?: boolean;
    isFailureGlobalTrigger?: boolean;
  };
  mutationOptions?: MutatorOptions;
};

type UseSWRTriggerParams = {
  url: string;
  swrKey?: string;
  requestType?: 'GET' | 'POST' | 'PUT' | 'DELETE';
};

export function useMutation<Params, Response>({
  url,
  swrKey,
  requestType = 'POST',
}: UseSWRTriggerParams) {
  const { showNotification } = useContext(NotificationContext);

  const { trigger } = useSWRMutation(
    swrKey || url,
    function (_, { arg }: { arg: Params }) {
      return fetch(url, {
        method: requestType,
        body: JSON.stringify(arg),
      });
    },
  );

  return async function ({
    args,
    options = {
      displayToast: true,
      isSuccessGlobalTrigger: true,
      isFailureGlobalTrigger: false,
    },
    mutationOptions,
  }: UseMutationParams<Params>): Promise<Response | undefined> {
    try {
      const response = await trigger({ arg: args } as null & Params, {
        rollbackOnError: true,
        ...mutationOptions,
      });

      const result = await response?.json();

      if (options?.displayToast !== false) {
        if (!result.ok) {
          showNotification({
            message: result.message,
            type: 'error',
            isGlobalTrigger: !!options?.isFailureGlobalTrigger,
          });
          return result;
        } else {
          showNotification({
            message: result.message,
            type: 'success',
            isGlobalTrigger: !!options?.isSuccessGlobalTrigger,
          });
          return result;
        }
      } else {
        return result;
      }
    } catch (error) {
      showNotification({
        message: getErrorMessage(error),
        type: 'error',
        isGlobalTrigger: !!options?.isFailureGlobalTrigger,
      });
      return;
    }
  };
}
