import { unreachable } from '@login/utils/unreachable';
import classnames from 'classnames';

export type TooltipPosition =
  | 'top-left'
  | 'bottom'
  | 'top-right'
  | 'right'
  | 'center'
  | 'bottom-left';

type Props = {
  children: JSX.Element;
  title: React.ReactNode | string;
  position?: TooltipPosition;
};

export function Tooltip(props: Props) {
  const { children, title, position } = props;

  if (!title) {
    return children;
  }

  const wrapperClassNames = classnames('flex relative group/tooltip');

  const classNames = classnames(
    getPosition(position),
    'whitespace-nowrap transition-opacity bg-ice-grey-10 p-2 rounded-md absolute z-10 hidden',
    'text-xs text-white',
    'group-hover/tooltip:block',
  );

  return (
    <div className={wrapperClassNames}>
      {children}
      <span className={classNames}>{title}</span>
    </div>
  );
}

const getPosition = (position: TooltipPosition | undefined) => {
  switch (position) {
    case 'top-left':
      return ['-translate-y-full -translate-x-full'];
    case 'top-right':
      return ['-top-1 -translate-y-full right-0 translate-x-full'];
    case 'right':
      return ['top-1/2 -translate-y-1/2 -right-3 translate-x-full'];
    case 'center':
      return ['top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'];
    case 'bottom':
    case undefined:
      return ['-bottom-2 translate-y-full left-1/2 -translate-x-1/2'];
    case 'bottom-left':
      return ['-bottom-3 translate-y-full -translate-x-3/4'];
    default:
      unreachable(position);
  }
};
