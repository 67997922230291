import { FormControl } from '@login/types';
import { Controller, FieldValues } from 'react-hook-form';

import { Input, InputProps } from '../Input';

type Props<T extends FieldValues> = FormControl<T> & InputProps;

export function FormInput<T extends FieldValues>(props: Props<T>) {
  const { control, name, ...inputProps } = props;

  return (
    <Controller
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Input
          {...inputProps}
          ref={undefined}
          onChange={onChange}
          value={value ?? ''}
          error={error?.message}
          name={name}
        />
      )}
      name={name}
    />
  );
}
