export const API_ENDPOINTS = {
  auth: {
    loginWithToken: '/api/auth/loginWithToken',
    forgotPassword: '/api/auth/forgot-password',
    login: '/api/auth/login',
    loginWithGoogle: '/api/auth/loginWithGoogle',
    resetPassword: '/api/auth/reset-password',
    signup: '/api/auth/signup',
    signupWithGoogle: '/api/auth/signupWithGoogle',
    logout: '/api/auth/logout',
  },
  credentials: {
    fetch: '/api/credentials/fetch',
    base: '/api/credentials',
  },
  user: {
    update: '/api/user/update',
    changeEmail: '/api/user/changeEmail',
  },
};
