import classnames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
};

export function InputLabel(props: Props) {
  const { children, className } = props;

  const classNames = classnames(
    'font-medium leading-5 text-regular-grey-10 mb-2 block text-sm',
    className,
  );

  return <label className={classNames}>{children}</label>;
}
