'use client';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons/faCircleQuestion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownOptions } from '@login/types';
import classnames from 'classnames';
import React, { forwardRef, ReactElement, ReactNode } from 'react';

import { Dropdown } from './Dropdown';
import { InputLabel } from './InputLabel';
import { Tooltip, TooltipPosition } from './Tooltip';

type RootProps = React.ComponentProps<'input'>;

type Variant = 'info' | 'base';

type IconPosition = 'right' | 'left' | 'both';

export interface InputProps extends RootProps {
  label?: string;
  icon?: ReactNode;
  secondIcon?: ReactNode;
  iconPosition?: IconPosition;
  error?: string;
  variant?: Variant;
  hint?: string;
  tooltipPosition?: TooltipPosition;
  showDropdown?: boolean;
  dropdownOptions?: DropdownOptions;
  dropdownTrigger?: ReactElement;
  footer?: string | ReactNode;
  maxLength?: number;
  containerClassNames?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      label,
      icon,
      error,
      required,
      tooltipPosition,
      secondIcon,
      variant = 'base',
      iconPosition = 'left',
      hint,
      showDropdown = false,
      dropdownTrigger,
      dropdownOptions = [],
      disabled = false,
      footer,
      maxLength = 255,
      containerClassNames,
      ...props
    },
    ref,
  ) => {
    const rightIcon = icon && iconPosition === 'right';
    const leftIcon = icon && iconPosition === 'left';
    const bothIcons = iconPosition === 'both';
    const inputPaddingRight = showDropdown ? 'pr-20' : '';

    const classNames = classnames(
      'bg-white shadow-sm py-[10px] px-[13px] rounded-sm w-full m-0',
      'leading-5 font-normal text-sm text-regular-grey-10',
      error
        ? 'border-1 focus:outline-none !focus:ring-destructive-7 focus:shadow-md'
        : 'border border-[#D4D4D4] focus:outline-none focus:border-[#172029] focus:ring-0',
      (leftIcon || bothIcons) && 'pl-10',
      (error || variant === 'info' || rightIcon || bothIcons) && 'pr-[33px]',
      inputPaddingRight,
      error && 'border !border-destructive-7 ring-1 !ring-destructive-7',
      disabled && 'bg-regular-grey-1 cursor-not-allowed opacity-50',
      className,
    );

    function renderIcon(currentIcon: ReactNode, position: IconPosition) {
      const isSecondIcon = position === 'right' && iconPosition === 'both';
      const isRightIcon = position === 'right' && !isSecondIcon;
      const iconClasses = classnames(
        'absolute flex items-center justify-center top-1/2 translate-y-[-50%] pointer-events-auto h-full',
        position === 'left' && 'left-0 pl-4',
        position === 'right' && 'right-0 pr-4',
        (icon || variant === 'info') && 'text-regular-grey-5',
        error && isRightIcon && '!text-destructive-7',
      );

      const defaultIcon = <span className={iconClasses}>{currentIcon}</span>;

      const errorOrInfoIcon = (
        <span className={iconClasses}>
          {hint ? (
            <Tooltip title={`${hint}`} position={tooltipPosition}>
              <FontAwesomeIcon
                icon={error ? faCircleExclamation : faCircleQuestion}
              />
            </Tooltip>
          ) : (
            <FontAwesomeIcon
              icon={error ? faCircleExclamation : faCircleQuestion}
            />
          )}
        </span>
      );

      if (currentIcon && position === 'left') {
        return defaultIcon;
      }

      if (
        (error && position === 'right' && !isSecondIcon) ||
        (variant === 'info' && position === 'right' && !isSecondIcon)
      ) {
        return errorOrInfoIcon;
      }

      if (isRightIcon && error && !currentIcon) {
        return errorOrInfoIcon;
      }

      if (isRightIcon && error && !currentIcon) {
        return errorOrInfoIcon;
      }

      if (position === 'right' && currentIcon) {
        return defaultIcon;
      }

      return null;
    }

    return (
      <div
        className={classnames(
          'flex flex-col w-full text-sm',
          containerClassNames,
        )}
      >
        {label && (
          <InputLabel>
            {label}
            {required && <span className="ml-1 text-destructive-8">*</span>}
          </InputLabel>
        )}

        <div className="relative w-full">
          {renderIcon(icon, 'left')}
          <input
            maxLength={maxLength}
            ref={ref}
            disabled={disabled}
            className={classNames}
            {...props}
          />
          {(iconPosition === 'right' || iconPosition === 'both' || error) &&
            renderIcon(iconPosition === 'both' ? secondIcon : icon, 'right')}
          {showDropdown && (
            <div className="absolute right-0 top-0 mt-2.5 mr-2 bg-white">
              <Dropdown
                showDivider={false}
                trigger={dropdownTrigger}
                options={dropdownOptions}
              />
            </div>
          )}
        </div>
        {footer ? (
          <div className="flex items-center text-ice-grey-7 text-xs font-medium leading-3 tracking-wide mt-4">
            {footer}
          </div>
        ) : null}
        {error ? <div className="text-destructive-9 mt-2">{error}</div> : null}
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
