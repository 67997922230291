import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { unreachable } from '@login/utils/unreachable';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

type RootProps = React.ComponentProps<'button'>;

type ButtonState = 'idle' | 'success' | 'error';

type StatusVariant = 'success' | 'error' | 'success-white' | 'error-white';
type Variant =
  | 'primary'
  | 'secondary'
  | 'white'
  | 'text'
  | 'danger'
  | 'link'
  | 'danger-secondary'
  | 'outline-primary'
  | StatusVariant;
type Size = 'xs' | 'sm' | 'base' | 'lg' | 'xl';
type IconPosition = 'right' | 'left';

interface ButtonProps extends RootProps {
  variant?: Variant;
  size?: Size;
  icon?: IconDefinition;
  isLoading?: boolean;
  iconPosition?: IconPosition;
  iconClasses?: string;
  withStatus?: boolean;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => Promise<void> | void;
  errorText?: string;
  status?: ButtonState;
}

export const Button = ({
  children,
  variant = 'primary',
  size = 'base',
  disabled = false,
  icon,
  className,
  isLoading,
  iconPosition = 'left',
  iconClasses,
  onClick,
  withStatus = false,
  errorText,
  status: _status = 'idle',
  ...props
}: ButtonProps) => {
  const [status, setStatus] = useState<ButtonState>(_status);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      try {
        await onClick?.(event);
        if (withStatus) {
          setStatus('success');
          setTimeout(() => setStatus('idle'), 2000);
        }
      } catch (error) {
        if (withStatus) {
          setStatus('error');
          setTimeout(() => setStatus('idle'), 2000);
        }
      }
    }
  };

  const iconByStatus: Record<ButtonState, IconDefinition | undefined> = {
    idle: isLoading ? faSpinner : icon,
    success: icon || status === 'success' ? faCheck : undefined,
    error: faCircleExclamation,
  };

  const colorByStatus: Record<ButtonState, string> = {
    idle: '',
    success: 'text-success-9',
    error: 'text-destructive-8',
  };
  const originalVariant = variant;

  const variantByStatus: Record<ButtonState, Variant> = {
    idle: variant,
    success: originalVariant === 'white' ? 'success-white' : 'success',
    error: originalVariant === 'white' ? 'error-white' : 'error',
  };

  const classNames = classnames(
    getSize(size),
    getAppearance(variantByStatus[status]),
    'flex items-center justify-center rounded-sm gap-2 whitespace-nowrap text-sm',
    disabled && 'cursor-not-allowed',
    className,
  );

  const Icon = () => {
    const currentIcon = iconByStatus[status];
    return currentIcon ? (
      <FontAwesomeIcon
        className={classnames(
          iconClasses,
          colorByStatus[status],
          status === 'error' && 'animate-shake',
        )}
        icon={currentIcon}
        spin={isLoading}
      />
    ) : null;
  };

  useEffect(() => {
    setStatus(_status);
  }, [_status]);

  return (
    <button
      className={classNames}
      disabled={disabled || isLoading || status === 'success'}
      onClick={handleClick}
      {...props}
    >
      {iconPosition === 'left' && <Icon />}
      {status === 'error' ? errorText : children}
      {iconPosition === 'right' && <Icon />}
    </button>
  );
};

const getAppearance = (variant: Variant | undefined) => {
  switch (variant) {
    case undefined:
    case 'primary':
      return [
        'bg-primary-blue border border-primary-blue text-white shadow-sm',
        'hover:bg-primary-dark-2',
      ];
    case 'secondary':
      return [
        'bg-primary-light-2 text-primary-blue shadow-sm',
        'hover:bg-primary-light-3',
        'focus-visible:text-primary-light-3 focus-visible:bg-primary-light-1',
      ];
    case 'white':
      return [
        'bg-white text-ice-grey border border-ice-grey-9 shadow-sm',
        'hover:bg-regular-grey-2',
        'focus-visible:border-ice-grey-8 focus-visible:text-ice-grey-8',
      ];
    case 'danger':
      return [
        'bg-destructive-7 text-white shadow-sm',
        'hover:bg-destructive-8',
      ];
    case 'danger-secondary':
      return [
        'bg-destructive-1 text-destructive-9 shadow-sm',
        'hover:bg-destructive-3',
      ];
    case 'text':
      return [
        'text-regular-grey-8',
        'hover:text-regular-grey-6 hover:underline',
      ];
    case 'outline-primary':
      return [
        'bg-white text-primary-blue border border-primary-blue shadow-sm',
        'hover:bg-primary-light-1',
        'focus-visible:ring-2 focus-visible:ring-primary-blue focus-visible:ring-offset-2',
      ];
    case 'link':
      return ['text-primary-blue !p-0'];
    case 'success-white':
      return ['bg-white border border-success-9 text-success-9'];
    case 'success':
      return ['bg-success-2 border-success-9 text-success-9'];
    case 'error-white':
      return ['bg-white border border-destructive-8 text-destructive-8'];
    case 'error':
      return ['bg-destructive-1 border-destructive-8 text-destructive-8'];
    default:
      unreachable(variant);
  }
};

const getSize = (size: Size | undefined) => {
  switch (size) {
    case 'xs':
      return 'text-sm leading-4 py-[7px] px-[11px]';
    case 'sm':
      return 'leading-4 py-[10px] px-[13px]';
    case undefined:
    case 'base':
      return 'leading-5 py-[10px] px-[17px]';
    case 'lg':
      return 'leading-6 py-[10px] px-[17px]';
    case 'xl':
      return 'leading-6 py-[13px] px-[25px]';
    default:
      unreachable(size);
  }
};
