import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { DropdownOptions } from '@login/types';
import classnames from 'classnames';
import { Fragment, ReactElement, useCallback, useState } from 'react';

type Props = {
  options: DropdownOptions;
  trigger?: ReactElement;
  title?: ReactElement | string;
  showDivider?: boolean;
  testId?: string | number;
  triggerType?: 'button' | 'div' | 'span';
};

export function Dropdown(props: Props) {
  const {
    options,
    trigger,
    title,
    testId,
    showDivider = true,
    triggerType = 'button',
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const onDropdownButtonClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
    },
    [],
  );

  const itemClasses = classnames(
    'block min-w-[120px] py-[12px] px-[16px] w-full',
    'leading-5 text-left text-regular-grey-8',
    'hover:bg-regular-grey-1',
  );

  const buttonClasses = classnames(
    'flex justify-center items-center rounded-full w-8 h-8 bg-regular-grey-2',
    'text-center text-regular-grey-5',
    'hover:text-regular-grey-7',
    'focus:outline-2 focus:outline-primary-blue',
  );

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div onClick={onDropdownButtonClick}>
        <Menu.Button
          as={triggerType}
          aria-label="Menu"
          className={!trigger ? buttonClasses : undefined}
          onClick={() => setIsOpen(!isOpen)}
        >
          {trigger || (
            <FontAwesomeIcon
              icon={faEllipsisV}
              size="lg"
              data-testid={`dropdown-trigger${testId ? `-${testId}` : ''}`}
            />
          )}
        </Menu.Button>
      </div>

      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          data-testid="dropdown-menu-items"
          className="absolute overflow-hidden right-0 z-10 mt-2 whitespace-nowrap origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div
            className={
              showDivider ? 'divide-y-2 divide-regular-grey-2' : undefined
            }
          >
            {title && (
              <div className="p-3 font-bold text-left text-regular-grey-8">
                {title}
              </div>
            )}
            {options.map((option, index) => (
              <Menu.Item key={`dropdown-option-${index}`}>
                {() => (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      option.onClick();
                      setIsOpen(false);
                    }}
                    className={itemClasses}
                  >
                    {option.title}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
